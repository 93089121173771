import { Subject } from 'rxjs';
import {GroupBy, IsEmpty, IsNaN, Map} from "react-lodash"
import map from 'lodash/map'
import Util from "./Util";
import Site from "./Site";

export default class Cart {
    static instance = Cart.instance || new Cart()

    cart=[];
    debugOrderText="";
    deliveryMethod="Delivery";
    paymentMethod="Online";
    totalCartValue=0;
    subtotalMinusDiscount=0;
    subtotal=0;
    totalCartFoodCount= 0;
    deliveryCharge=0;
    serviceCharge=0;
    discount=0;
    voucherCode='';
    deliveryAddress={
        person:"",
        address1:"",
        address2:"",
        postcode:"",
        city:"",
        phone:"",
        addressnote:""
    }

    simpleProps = ["cart","deliveryMethod","paymentMethod","totalCartValue",
        "subtotal","totalCartFoodCount","deliveryCharge","discount","deliveryAddress"]



    clearCart(){
        this.cart=[];
        this.deliveryMethod="Delivery";
        this.paymentMethod="Online";
        this.totalCartValue=0;
        this.subtotal=0;
        this.totalCartFoodCount= 0;
        this.deliveryCharge=0;
        this.serviceCharge=0;
        this.discount=0;
        this.deliveryAddress={
            person:"",
            address1:"",
            address2:"",
            postcode:"",
            city:"",
            phone:"",
            addressnote:""
        }
    }

    copyProps(src, dst){
        for (let i in this.simpleProps)
            dst[this.simpleProps[i]] = src[this.simpleProps[i]]
    }
    saveToStorage(){
        if (!this.totalCartFoodCount) return;
        let data={}
        data._t=+new Date()
        this.copyProps(this, data)
        localStorage.setItem("cart", JSON.stringify(data));
    }
    loadFromStorage(){
        let data = localStorage.getItem("cart");
        if (!data) return;
        try {
            data = JSON.parse(data)
        } catch(e) {
            localStorage.removeItem("cart")
            data=null
            return
        }

        let now=+new Date()
        if (now-data._t<15*60*1000) {
            this.copyProps(data, this)
            this.cartSubject.next("")
        }else{
            localStorage.removeItem("cart")
        }

    }
    cartSubject=new Subject();
    showDialogSubject = new Subject();

    addToCart(food) {
        if (food.prices.length === 1) {
            let food2 = JSON.parse(JSON.stringify(food));
            food2.total = food2.prices[0].price * 100/100 ;
            this.addToCartObject(food2);
        }
    }

    findToppingPrice(food, topping, toppingprice) {
        let tcategoryids = Site.instance.foodinfo.foodtcategory.filter(cat=> cat.foodid===food.foodid).map(cat=>cat.tcategoryid);
        if (tcategoryids.length===0) tcategoryids.push(-1);
        let size = food.selectedSize;
        if (!size) size=null;
        let sizeid = size===null? null : size.id;
        let prices = toppingprice.filter((p) =>
                (p.tcategoryid === null || tcategoryids.includes(p.tcategoryid)) &&
                (p.toppingid ===null || p.toppingid === topping.toppingid) &&
                (p.sizeid===null || p.sizeid === sizeid));
        prices.sort().reverse();

        if (prices.length>0){
            let price=prices[0];
            return [price.price*1, topping.count];
        }
        return [0, topping.count];
    }

    changeDeliveryMethod(method){
        this.deliveryMethod=method;
        this.updateTotal();
    }
    changePaymentMethod(method){
        this.paymentMethod=method;
        this.updateTotal();
    }
    removeFromCart(food) {
        let i = this.cart.indexOf(food);
        if (i>-1) {
            if (this.cart[i].count > 1)
                this.cart[i].count--;
            else {
                this.cart.splice(i, 1);
            }
            this.updateTotal()
        }
    }
    computePercentagePrice(amount, percent){
        return (amount * percent).toFixed(0) / 100
    }
    findPriceByOptions(food, model, size, freeToppingCount) {
        console.log("FTC: " + freeToppingCount)
        let price = null;
        if (!model) model = null;
        if (!size) size = null;

        if (!model) model = null;
        if (!size) size = null;

        if (!food || !food.prices) return null;
        if (!size && model)
            price = food.prices.find(x => x.modelid === model.id && x.sizeid === "0");
        if (size && !model)
            price = food.prices.find(x => x.sizeid === size.id && x.modelid === "0");
        if (model && size)
            price = food.prices.find(x => x.sizeid === size.id && x.modelid === model.id);
        if (food.prices.length===1)
            price=food.prices[0];
        if (!food.prices || food.prices.length===0){
            Site.instance.showMessageBox("Error adding food to basket", "Error", "")
            return 9999;
        }
        food.toppings && food.toppings.forEach(topping =>{
            if (!('count' in topping)) topping.count = topping.checked * 1;
        });

        let abbrsm = food.toppings? food.toppings.filter(topping =>
              topping.checked * 1===1 && topping.count===0
        ).map((topping) => `-${topping.abbr}`): [];
        let abbrsp = food.toppings? food.toppings.filter(topping =>
            topping.count-topping.checked * 1>0
            ).map((topping) => `+${(topping.count-topping.checked * 1)===1?"":(topping.count-topping.checked * 1)}${topping.abbr}`):[];

        let abbrs = abbrsm.concat(abbrsp);
        food.toppingsabbr=abbrs.join(' ');
        food.lesstoppings = (food.toppings? food.toppings.filter(topping =>
            topping.checked * 1===1 && topping.count===0
        ).map((topping) => `${topping.toppingid}`): []).join(',');
        food.extratoppings = (food.toppings? food.toppings.filter(topping =>
            topping.count-topping.checked * 1>0
        ).map((topping) => `${topping.toppingid}`):[]).join(',');

        let baseselected = food.toppings && food.toppings.filter(topping => topping.checked*1 > 0);
        let selected = food.toppings && food.toppings.filter((topping) => topping.count > 0);
        let basetpsList = [];
        let tpsList = [];

        baseselected && baseselected.forEach((t) => {
            let p = this.findToppingPrice(food, t, Site.instance.foodinfo.toppingprice);
            if (p) {
                let tp = p[0];
                basetpsList.push(tp);
            }
        })

        selected && selected.forEach((t) => {
            let p = this.findToppingPrice(food, t, Site.instance.foodinfo.toppingprice);
            if (p) {
                let tp = p[0];
                let tc = p[1];
                for (let i = 0; i < tc; i++) tpsList.push(tp);
            }
        })
        tpsList.sort();

        let freeCount = freeToppingCount - (baseselected? baseselected.length: 0)
        let tpsListP = tpsList.slice(freeCount,tpsList.length);
        let basetps = basetpsList.reduce((a, b) => a + b, 0)
        let tps = tpsListP.reduce((a, b) => a + b, 0)
        let tpsDiff = ((tps-basetps)>0)? tps-basetps : 0

        let totalPrice = price ? Math.round((price.price * 1 + tpsDiff)*100)/100 : undefined;
        return totalPrice;
    }

    computeFoodHash(food){
        let f1 = JSON.parse(JSON.stringify(food));
        delete f1.count
        delete f1.prices
        delete f1.toppings
        if (food.details)
            food.details.map((detail)=>{
                if (detail.selectedFood) {
                    delete detail.selectedFood.prices
                    delete detail.selectedFood.toppings
                }
        })
        return Util.instance.hash(JSON.stringify(f1));
    }

    addOfferToCart(offer){
        if (!offer.count) offer.count=1;
        let extra = 0;
        offer.details.map((detail) => {
            if (detail.selectedFood && detail.selectedFood.extraOfferPrice) extra+=detail.selectedFood.extraOfferPrice;
            if (detail.selectedFood) {
                detail.selectedFood.offerdetailid=detail.offerdetailid
                delete detail.selectedFood.prices
                delete detail.selectedFood.toppings
            }
        })
        offer.total =Math.round((offer.price*1 + extra)*100)/100
        this.addToCartObject(offer);
    }
    addToCartWithOptions(foodToAdd) {
        let food = foodToAdd ; //JSON.parse(JSON.stringify(foodToAdd));
        let p = food.totalWithOptionPrice + (food.extraOfferPrice? food.extraOfferPrice: 0);
        // p = this.findPriceByOptions(foodToAdd, foodToAdd.selectedModel, foodToAdd.selectedSize);
        if (p===undefined)
            return false;
        food.total = Math.round(p*100)/100;
        this.addToCartObject(food);
        return true;

    }
    addToCartObject(food) {
        if (!('count' in food)) food.count = 1;
        delete food.prices
        delete food.toppings

        food.hash = this.computeFoodHash(food)
        let foodexisted = this.cart.find(c => c.hash === food.hash);
        let newcart = this.cart;
        if (foodexisted)
            foodexisted.count+=food.count;
        else {
            newcart = [...this.cart, food];
            this.cart=newcart;
        }
        this.updateTotal()
    }

    deliveryChargeCalc(postcode, deliveryChargeRules, deliveryMethod) {
        if (deliveryMethod === "Collection") return 0;
        if (!deliveryChargeRules || deliveryChargeRules === '' || !postcode) return 0;
        if (!isNaN(deliveryChargeRules)) return deliveryChargeRules*1;
        postcode = postcode.replace(" ", "");
        let lastDigit = 0;
        for (let i = postcode.length - 1; i >= 0; i--)
            if (!isNaN(postcode.charAt(i))) {
                lastDigit = i;
                break
            }
        let postcode1 = postcode.substring(0, lastDigit).toLowerCase();
        let deliveryChargeRules2 = deliveryChargeRules.toLowerCase().replace(' ', '').split(";");
        let charge = -1;
        for (let i = 0; i < deliveryChargeRules2.length; i++) {
            if (deliveryChargeRules2[i].indexOf(":") === -1) continue;
            let d3 = deliveryChargeRules2[i].split(":");
            if (d3[1] === '') continue;
            if (d3[0].split(',').indexOf(postcode1) > -1) {
                charge = d3[1] * 1;
                break;
            }
        }
        return charge;
    }


    setDeliveryAddress(address){
        this.deliveryAddress.person=address.person;
        this.deliveryAddress.address=address.address;
        this.deliveryAddress.postcode=address.postcode;
        this.deliveryAddress.city=address.city;
        this.deliveryAddress.phone=address.phone;
    }
    updateTotal() {
        this.subtotal = Math.round(this.cart.reduce((a, b) => a * 1 + b.total * b.count, 0) * 100) / 100
        this.totalCartFoodCount = this.cart.reduce((a, b) => a * 1 + b.count, 0)
        let totalOffers = Math.round(this.cart.filter(item => 'details' in item).reduce((a, b) => a * 1 + b.total * b.count, 0) * 100) / 100
        this.deliveryCharge = Math.round(this.getDeliveryCharge(this.deliveryMethod) * 100) / 100;
        this.serviceCharge=0
        let buy1get1_discount = 0
        let buy1get1_discount_off = 0
        if (Site.instance.site.buy1get1_food_type_id != null &&
            Site.instance.site.buy1get1_food_type_id.length > 0) {
            let foodids = Array.from(new Set(this.cart.map(o => o.typeid)));
            let b1g1 = Site.instance.site.buy1get1_food_type_id.split(',');
            foodids = foodids.filter(item => Site.instance.site.buy1get1_food_type_id == '0' || b1g1.indexOf(item) > -1);
            for (let i = 0; i < foodids.length; i++) {
                let foods = this.cart.filter(f => f.typeid == foodids[i])
                let foodtotals = []
                for (let f = 0; f < foods.length; f++)
                    for (let j = 0; j < foods[f].count; j++)
                        foodtotals.push(foods[f].total * 1)
                foodtotals = foodtotals.sort()
                let half = foodtotals.slice(0, Math.floor(foodtotals.length / 2)).reduce((a, b) => a + b, 0)
                buy1get1_discount += half
                buy1get1_discount_off += foodtotals.reduce((a, b) => a + b, 0)
            }
        }
        let online_discount_total = 0;
        let online_discount_min = !isNaN(parseFloat(Site.instance.site.online_discount_min)) ? parseFloat(Site.instance.site.online_discount_min) : 0;
        if (!isNaN(parseInt(Site.instance.site.online_discount)) && this.subtotal>=online_discount_min) {
            if (parseInt(Site.instance.site.is_online_discount_for_basket) == 1) {
                online_discount_total = (Math.floor((this.subtotal - buy1get1_discount) * Site.instance.site.online_discount) / 100).toFixed(2) * 1;
                this.discount = online_discount_total + buy1get1_discount;
            } else {
                online_discount_total = (Math.floor((this.subtotal - totalOffers - buy1get1_discount_off) * Site.instance.site.online_discount) / 100).toFixed(2) * 1;
                this.discount = online_discount_total + buy1get1_discount;
            }
        }
        if (Site.instance.site.first_order_off && Site.instance.site.first_order_off>0 && Site.instance.auth && Site.instance.auth.first_order && Site.instance.auth.first_order===1 && this.subtotal>=online_discount_min){
            this.voucherCode='firstorder'
            if (parseInt(Site.instance.site.is_online_discount_for_basket) == 1) {
                this.discount = this.computePercentagePrice( this.subtotal, Site.instance.site.first_order_off);
            } else {
                this.discount = this.computePercentagePrice( this.subtotal - totalOffers, Site.instance.site.first_order_off);
            }

        }
        this.subtotalMinusDiscount =  this.subtotal - this.discount
        this.totalCartValue = this.subtotal - this.discount + Math.max(0, this.deliveryCharge);
        if (this.totalCartValue>0 && Site.instance.site.stripe_service_charge !== ''){
            if (this.paymentMethod!=='Cash') {
                this.totalCartValue += Site.instance.site.stripe_service_charge * 1;
                this.serviceCharge = Site.instance.site.stripe_service_charge * 1;
            }
        }
        this.totalCartValue=Math.round(this.totalCartValue*100)/100;
        this.cartSubject.next("changed");
    //    console.log("cart totalcartvalue: " + this.totalCartValue);
    }
    getDeliveryCharge(deliveryMethod){
        let deliveryCharge =0;
        let postcode = this.deliveryAddress && this.deliveryAddress.postcode && this.deliveryAddress.postcode!=="" ? this.deliveryAddress.postcode :
            Site.instance.auth && Site.instance.auth.postcode && Site.instance.auth.postcode!=="" ? Site.instance.auth.postcode : "";
        if (deliveryMethod === "Delivery" && postcode !== '')
            deliveryCharge = this.deliveryChargeCalc(postcode, Site.instance.site.delivery_charge, deliveryMethod);
        return deliveryCharge;
    }

    checkBeforePlaceOrder() {
        if (Cart.instance.deliveryMethod=='Delivery' && Cart.instance.subtotalMinusDiscount<Site.instance.site.minorder*1){
            Site.instance.showToast("Minimum order is " + Util.instance.formatPrice(Site.instance.site.minorder), "error", "bottom");
            return false;
        }
        let notForDelivery = this.cart.filter(f=>f.fordelivery && f.fordelivery=='0').length
        if (Cart.instance.deliveryMethod=='Delivery' && notForDelivery>0){
            Site.instance.showToast("You have selected offers that are for collection only. Remove them!", "error", "bottom");
            return false;
        }

/*
        let notForTakeaway = this.cart.filter(f=>f.fordelivery && f.fortakeaway=='0').length;
        if (Cart.instance.deliveryMethod=='Collection' && notForTakeaway>0){
            Site.instance.showToast("You have selected offers that are for collection only. Remove them!", "error", "bottom");
            return false;
        }

 */
        return true;
    }
    checkBeforeCheckoutProceed(){
        if (Cart.instance.totalCartValue==0){
            return false;
        }
        if (Site.instance.site.shopStatus!=="open" && !Site.instance.isTestOrder()){
            Site.instance.showToast("Sorry! We are closed now.", "error", "bottom");
            return false;
        }
        //check for 1/4
        let halfMeterCount=0;
        Cart.instance.cart.forEach((f)=>{
            if (f.selectedSize && f.selectedSize.abr && f.selectedSize.abr.indexOf('1/4')>-1)
                halfMeterCount+=f.count;
            if (f.details){
                f.details.forEach((d)=>{
                    if (d.selectedFood) {
                        let f1=d.selectedFood
                        if (f1.selectedSize && f1.selectedSize.abr && f1.selectedSize.abr.indexOf('1/4')>-1)
                            halfMeterCount+=f.count;
                    }
                })
            }
        })
        if (halfMeterCount%2===1){
            // cannot checkout
            Site.instance.showToast("You must choose another Half meter pizza!", "error", "bottom");
            return false;
        }
        return true;
    }



}
